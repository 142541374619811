<template>
  <div class="common-padding">
    <div class="cart-content">
      <h2 class="common-h2">Confirm Order</h2>
      <div class="cart-item" v-for="item in cartList" :key="item.id">
        <div class="cart-item-info">
          <div class="cart-item-img border-common">
            <img :src="item.image">
          </div>
          <div class="product-info-txt">
            <p><span class="year">{{ item.year }}</span></p>
            <p><b>{{ item.name }}</b></p>
            <div>
              <span>{{ item.part_no }}</span> <span>x{{ item.quantity }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="thanks">
        <p>Thank you for your trust</p>
        <p> Please fill out the form below to confirm your order</p>
      </div>

      <div class="order-tips">
        <p>Replacement Parts take approximately 7-10  business days to receive.</p>
      </div>

      <div class="upload">
        <h3>Item/Defective Part Photo <b style="color: #ff0000;">*</b></h3>
        <p>Upload up to 3 images, with a maximum of 5M per image</p>
        <Upload @photos-updated="handlePhotosUpdated"/>
      </div>
    </div>
    <!-- 表格-->
    <div class="order-form">
      <div class="order-form-item">
        <p>Name<span>*</span></p>
        <input class="item-input" type="text" v-model="name" placeholder="Name">
      </div>
      <div class="order-form-item">
        <p>Store Name Or Website<span>*</span></p>
        <input class="item-input" type="text" v-model="place" placeholder="Store Name Or Website">
      </div>
      <div class="order-form-item">
        <p>Street Address<span>*</span></p>
        <input class="item-input" type="text" v-model="street" placeholder="Street Address">
      </div>
      <div class="order-form-item">
        <p>Country<span>*</span></p>
        <select v-model="countrySelected" class="country-select">
          <option value="" disabled>Select a country</option>
          <option v-for="country in country" :key="country.id" :value="country.name">
            {{ country.name }}
          </option>
        </select>
      </div>
      <div class="order-form-item">
        <p>City<span>*</span></p>
        <input class="item-input" type="text" v-model="city" placeholder="City">
      </div>
      <div class="order-form-item">
        <p>State<span>*</span></p>
        <input class="item-input" type="text" v-model="state" placeholder="State">
      </div>
      <div class="order-form-item">
        <p>Zip Code<span>*</span></p>
        <input class="item-input" type="text" v-model="zipCode" placeholder="Zip Code">
      </div>
      <div class="order-form-item">
        <p>Email Address<span>*</span></p>
        <input class="item-input" type="text" v-model="email" placeholder="Email Address">
      </div>
      <div class="order-form-item">
        <p>Phone Number<span>*</span></p>
        <input class="item-input" type="text" v-model="phone" placeholder="Phone Number">
      </div>
      <div class="order-form-item">
        <p>Purchase Product Name<span>*</span></p>
        <input class="item-input" type="text" v-model="productName" placeholder="Purchase Product Name">
      </div>
      <div class="order-form-item">
        <p>Questions to Supplier</p>
        <textarea class="textarea-question" v-model="question" placeholder="Questions to Supplier"></textarea>
      </div>
    </div>

    <div class="block"></div>

    <div class="fixed-btn">
      <div class="cart-confirm" @click="mobileSubmit()">
        Submit
      </div>
    </div>

  </div>

  <!--提示窗-->
  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>
</template>

<script>
import {getCart, getCountry, subForm} from "@/network/main";
import Upload from "@/components/Upload";
export default {
  name: "mobileOrder",
  components: {
    Upload
  },
  data() {
    return {
      cartList:[],
      name:'',
      street:'',
      country: [],
      countrySelected: '',
      city:'',
      state: '',
      zipCode:'',
      phone:'',
      place:'',
      email:'',
      productName:'',
      question:'',
      photos: [], // 图片和描述数组
      isPopupVisible: false,
      popupText: '',
    };
  },
  created() {
    //拿缓存数据
    const selectedIdsData = localStorage.getItem('selectedMobile');
    if (selectedIdsData) {
      this.selectedIds = JSON.parse(selectedIdsData);
    }
    //获取购物车
    this.getCart();
    this.getCountry();
  },
  methods: {
    //获取国家
    getCountry() {
      getCountry().then(res => {
        if (res.data.code === 200) {
          this.country = res.data.data;
        }
      });
    },

    //orderList
    getCart() {
      const ids = Object.keys(this.selectedIds);
      const idString = ids.join(',');
      console.log(idString)
      getCart(idString).then(res => {
        if (res.data.code === 200) {
          const cartData = res.data.data;
          this.cartList = cartData.map(item => {
            const quantity = this.selectedIds[item.id];
            return {
              ...item,
              quantity: quantity
            };
          });
        }
      });
    },
    //图片和描述
    handlePhotosUpdated(photos) {
      this.photos = photos.map((photo, index) => ({
        [`photos[${index}][file]`]: photo.file,
        [`photos[${index}][description]`]: photo.description
      }));
    },
    //提交订单
    mobileSubmit(){
      // 构造 components 数组
      const components = this.cartList.map((item, index) => ({
        [`components[${index}][id]`]: item.id,
        [`components[${index}][number]`]: item.quantity,
        [`components[${index}][year]`]: item.year
      }));

      //formData
      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('place_of_purchase', this.place);
      formData.append('street_address', this.street);
      if (this.email) {
        if (!this.validateForm()) {
          return;
        }
        formData.append('email_address', this.email);
      }
      formData.append('nation', this.countrySelected);
      formData.append('city', this.city);
      formData.append('state', this.state);
      formData.append('zip_code', this.zipCode);
      formData.append('product_name', this.productName);
      formData.append('phone_number', this.phone);
      formData.append('questions_supplier', this.question);


      // 添加 photos 数据到 formData
      // if (this.photos.length > 0) {
      //   for (let index = 0; index < this.photos.length; index++) {
      //     const photo = this.photos[index];
      //     for (const key in photo) {
      //       formData.append(key, photo[key]);
      //     }
      //     // 判断 photo.description 是否为空
      //     const descriptionKey = `photos[${index}][description]`;
      //     if (!photo[descriptionKey]) {
      //       this.isPopupVisible = true;
      //       this.popupText = 'Please provide a description for each photo.';
      //       setTimeout(() => {
      //         this.isPopupVisible = false;
      //       }, 2000);
      //       return;
      //     }
      //   }
      // }





      // 添加 components 数据到 formData
      components.forEach((component) => {
        for (const key in component) {
          formData.append(key, component[key]);
        }
      });

      this.isPopupVisible = true
      this.popupText="Please Wait....."


      //图片必填
      if (this.photos.length === 0) {
        this.isPopupVisible = true
        this.popupText="Please upload a picture"
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 2000);
        return;
      }else {
        for (let index = 0; index < this.photos.length; index++) {
          const photo = this.photos[index];
          for (const key in photo) {
            formData.append(key, photo[key]);
          }
        }
      }

      if (!this.name || !this.place || !this.street || !this.email || !this.productName || !this.phone || !this.countrySelected || !this.city || !this.state  || !this.zipCode === 0) {
        this.isPopupVisible = true
        this.popupText="Please fill in the required fields"
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 2000);
        return;
      }

      //接口
      subForm(formData).then((res)=>{
        this.isPopupVisible = false;
        if (res.data.code === 200) {
          this.$router.push({ path: '/mobileResult', query: { success: 'true' } });
          //清空缓存数据
          this.selectedIds = {};
          localStorage.removeItem('selectedMobile');
          localStorage.setItem('selectedLength', '0');
          this.cartList = [];
        } else {
          this.$router.push({ path: '/mobileResult', query: { success: 'false', errorMessage: res.data.message } });
        }
      }).catch(err=> {
        console.log(err)
      })
    },
    // 验证电子邮件
    validateForm() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.email || !emailRegex.test(this.email)) {
        this.isPopupVisible = true,
            this.popupText="Please fill in the correct email format"
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 2000);
        return false;
      }
      return true; // 表单验证通过
    },
    //号码验证
    validatePhoneNumber(phoneNumber) {
      const phoneRegex = /^\d+$/;
      return phoneRegex.test(phoneNumber);
    },

  }
}
</script>

<style scoped>
@import "~@/assets/css/mobile.css";
</style>